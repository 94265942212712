import React, { useState, useEffect } from 'react';
import { ChartOptions, TooltipModel } from 'chart.js';
import PolarAreaChart from './PolarAreaChart';
import { RegulationsCount } from '../types/Wastewater';

interface ChartDataProps {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    borderWidth: number;
  }[];
}

interface WastewaterPolarChartProps {
  data: RegulationsCount[];
  error: Error | null;
}

const WastewaterPolarChart: React.FC<WastewaterPolarChartProps> = ({ data, error }) => {
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const [modalText, setModalText] = useState<string | null>(null);
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });

  useEffect(() => {
    const labels = data.map((item) => item.regulation);
    const counts = data.map((item) => item.count);

    const backgroundColor = counts.map(() => `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`);
    const borderColor = counts.map(() => `rgba(0, 0, 0, 1)`);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Number of Companies',
          data: counts,
          backgroundColor,
          borderColor,
          borderWidth: 1,
        },
      ],
    });
  }, [data]);

  if (error && !data.length) {
    return <div>Failed to fetch the data</div>;
  }

  const hoverMessages = data.map((item) => {
    if (item.regulation === "MISA COMPLIANCE") {
      return `${item.count} companies follow this regulation. They are regulations under Ontarios Municipal-Industrial Strategy for Abatement, focusing on reducing pollutants from industrial wastewater to protect water quality.`
    } else if (item.regulation === "CONTROL ORDER") {
      return `${item.count} companies follow this regulation. It requires companies to follow environmental regulations that mandate the management and reduction of specific pollutants. This includes monitoring emissions and regular reporting to regulatory authorities. Failure to comply can result in penalties and operational restrictions, emphasizing the critical role of these regulations in protecting the environment.`;
    } else {
      // Default message for other regulations
      return `${item.count} companies follow this regulation. It requires obtaining approval from environmental authorities for projects that may affect the environment. This certification ensures that activities meet regulatory standards, promoting public health and environmental protection through ongoing monitoring and reporting.`;
    }
  });

  const options: ChartOptions<'polarArea'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'left',
      },
      tooltip: {
        enabled: false,
        external: (context: { tooltip: TooltipModel<'polarArea'> }) => {
          const tooltip = context.tooltip;
          if (tooltip.opacity === 0) {
            setModalText(null);
            return;
          }

          const dataIndex = tooltip.dataPoints[0].dataIndex;
          setModalText(hoverMessages[dataIndex]);
          
          // Calculate position based on tooltip position
          setModalPosition({
            top: tooltip.caretY || 0,
            left: tooltip.caretX || 0,
          });
        },
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: Math.max(...(chartData?.datasets[0].data || [])) * 1.1,
      },
    },
  };

  return (
    <div style={{ width: '70%', height: '70%', margin: '0 auto', position: 'relative' }}>
      <h2 style={{ paddingBottom: 0, marginBottom: 0 }}>Wastewater Regulation Standards Polar Area Chart</h2>
      {chartData && <PolarAreaChart data={chartData} options={options} />}
      
      {modalText && (
        <div
          style={{
            position: 'absolute',
            top: modalPosition.top,
            left: modalPosition.left,
            transform: 'translate(-50%, -100%)', // Center and position above
            backgroundColor: 'white',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            pointerEvents: 'none', // Ensures it won't interfere with hover
          }}
        >
          <p>{modalText}</p>
        </div>
      )}
    </div>
  );
};

export default WastewaterPolarChart;