import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <ul>
        <li><NavLink to="/" className={({isActive}) => (isActive ? "active" : "")}>Home</NavLink></li>
        <li><NavLink to="/sectors" className={({isActive}) => (isActive ? "active" : "")}>Sector Info</NavLink></li>
        <li><NavLink to="/regions" className={({isActive}) => (isActive ? "active" : "")}>Region Info</NavLink></li>
        <li><NavLink to="/visualizations" className={({isActive}) => (isActive ? "active" : "")}>Visualizations</NavLink></li>
        <li><NavLink to="/about-us" className={({isActive}) => (isActive ? "active" : "")}>About Us</NavLink></li>
      </ul>
    </nav>
  );
};

export default Navbar;
