import React, { useState, useEffect } from 'react';
import BarChart from './BarChart';
import {
  getSewageNitrogenDataByRegion,
  getSewagePhosphorusDataByRegion
} from "../services/wastewaterService";
import { ChartData, listItemRegions } from '../types/Wastewater';


const SewageBarChart: React.FC = () => {
  const [selectedContaminant, setSelectedContaminant] = useState<string>('Nitrogen');
  const [sortOrder, setSortOrder] = useState<string>("DEFAULT");
  const [filteredData, setFilteredData] = useState<listItemRegions[]>([]);

  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch data
  const fetchData = async (contaminant: string) => {
    setLoading(true);
    try {
      const data = contaminant === 'Nitrogen'
        ? await getSewageNitrogenDataByRegion()
        : await getSewagePhosphorusDataByRegion();

      // Filter out outliers, values greater than 100
      const cleanedData = data.filter(item => item.pollutantAmt <= 100);

      setFilteredData(cleanedData);
    } catch (error) {
      console.error(`Error fetching ${contaminant} data:`, error);
    } finally {
      setLoading(false);
    }
  };

  const setupBarChart = (contaminant: string, chartData: listItemRegions[]) => {
    // Format the data to use in the chart
    const labels = chartData.map((item) => item.regionName);
    const values = chartData.map((item) => item.pollutantAmt);

    setChartData({
      labels,
      datasets: [
        {
          label: `${contaminant} Levels (mg/L)`,
          data: values,
          backgroundColor: contaminant === 'Nitrogen'
            ? 'rgba(75, 192, 192, 0.6)'
            : 'rgba(255, 159, 64, 0.6)',
          borderColor: contaminant === 'Nitrogen'
            ? 'rgba(75, 192, 192, 1)'
            : 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
        },
      ],
    });
  };

  // Sorts by pollutant amount
  const sortPollutantData = () => {
    if (filteredData && filteredData.length > 0) {
      let sortedData = filteredData.map(item => ({ ...item }));
      if (sortOrder === "ASC") {
        sortedData.sort((a, b) => a.pollutantAmt - b.pollutantAmt);
      } else if (sortOrder === "DESC") {
        sortedData.sort((a, b) => b.pollutantAmt - a.pollutantAmt);
      } else {
        sortedData = filteredData;
      }

      return sortedData;
    }
    return [];
  };

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      const sorted = sortPollutantData();
      setupBarChart(selectedContaminant, sorted);
    }
  }, [filteredData, sortOrder]);

  // Fetch data
  useEffect(() => {
    fetchData(selectedContaminant);
    const theData = sortPollutantData();
    setupBarChart(selectedContaminant, theData);
  }, [selectedContaminant]);

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ width: '80%', height: '80%', margin: '0 auto' }}>
        <h2>Sewage Contaminant Levels By Region</h2>
        {/* Dropdown to switch between Nitrogen and Phosphorus */}
        <label hidden htmlFor="contaminant_selector">Select Contaminant</label>
        <select
          id="contaminant_selector"
          value={selectedContaminant}
          onChange={(e) => setSelectedContaminant(e.target.value)}
        >
          <option value="Nitrogen">Nitrogen</option>
          <option value="Phosphorus">Phosphorus</option>
        </select>

        <label hidden htmlFor="sorting_selector">Select Sort Order</label>
        <select
          id="sorting_selector"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value="DEFAULT">No sorting</option>
          <option value="ASC">Ascending</option>
          <option value="DESC">Descending</option>
        </select>

        {/* Render the SewageBarChart with sewage data */}
        {chartData && <BarChart data={chartData} />}
      </div>
    );
};

export default SewageBarChart;

