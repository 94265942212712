import React, { useEffect, useState } from 'react';
import SewageBarChart from './SewageBarChart';
import WastewaterPolarChart from './WastewaterPolarChart';
import { RegulationsCount } from '../types/Wastewater';
import { getRegulationsCount } from '../services/wastewaterService';
import ExceedancesBarChart from './ExceedancesBarChart';

const Visualizations: React.FC = () => {
  const [regulationsCountData, setRegulationsCountData] = useState<RegulationsCount[]>([]);
  const [regulationsCountError, setRegulationsCountError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchChartData = async () => {
      // fetching regulations count data
      try {
        const data = await getRegulationsCount();
        setRegulationsCountData(data);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setRegulationsCountError(error);
        } else {
          setRegulationsCountError(new Error("Failed to fetch regulations count data."));
        }
      }
    };
    fetchChartData();   
  }, []);

  return (
    <>
      <SewageBarChart />
      <WastewaterPolarChart data={regulationsCountData} error={regulationsCountError} />
      <ExceedancesBarChart />
    </>
  );
}


export default Visualizations;