import React, { useState, useEffect } from "react";
import { getNitrogenDataByRegion } from "../services/wastewaterService";
import { listItemRegions } from "../types/Wastewater";

const NitrogenListRegionDisplay: React.FC<{order: string}> = ({ order })  => {
    const [list, setList] = useState<listItemRegions[]>([]);  // State to store the fetched list
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const [error, setError] = useState<string | null>(null); // Error state
  
    // Fetch data from the service when the component mounts
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getNitrogenDataByRegion();  // Fetch data using the service
          // console.log(data)
          let sortedData = data
          // ASC is ascending order, so starting with the lowest and increasing. DESC will be the opposite
          if (order === 'ASC') {
            sortedData = data.sort((a, b) => a.pollutantAmt - b.pollutantAmt)
          } else if (order === 'DESC') {
            sortedData = data.sort((a, b) => b.pollutantAmt - a.pollutantAmt)
          }
          sortedData = sortedData.slice(0, 5);
          setList(sortedData);
          setLoading(false);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setError(error.message);
          } else if (typeof error === 'string') {
            setError(error);
          } else {
            setError('An unknown error occurred');
          }
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  // Render a loading state, an error message, or the list of items
    return (
      <div>
        {loading && <p>Loading data...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Region</th>
                <th>Pollutant Amount (KG/D)</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={index} data-testid="nitrogenRegionListItem">
                  <td>{index + 1}.</td>
                  <td>{item.regionName}</td>
                  <td>{item.pollutantAmt.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };

export default NitrogenListRegionDisplay; 