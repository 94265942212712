import { listItem, listItemRegions, PollutantBySectorData, PollutantByRegionData, RegulationsCount, ExceedancesCount} from "../types/Wastewater";

export const getPhosphorusData = async (): Promise<listItem[]> => {
    try {
        const response = await fetch('/api/wastewater/sectors/phosphorus');
        if (!response.ok) {
            throw new Error("Failed to fetch phosphorus data");
        }
        const phosphorusData = await response.json();
        // Map the API response to your 'Item' structure
        const formattedPhosphorus = phosphorusData.map((item: PollutantBySectorData) => ({
            sectorName: item.sector,
            pollutantAmt: item.count,
        }));
        return formattedPhosphorus;
        } catch (error) {
            console.error("Error fetching phosphorus data:", error);
            return []; // Return an empty array on error
    }
};

export const getNitrogenData = async (): Promise<listItem[]> => {
    try {
        const response = await fetch('/api/wastewater/sectors/nitrogen');
        if (!response.ok) {
            throw new Error("Failed to fetch nitrogen data");
        }
        const nitrogenData = await response.json();
        
        // Map the API response to your 'Item' structure
        const formattedNitrogen = nitrogenData.map((item: PollutantBySectorData) => ({
            sectorName: item.sector,
            pollutantAmt: item.count,
        }));
        return formattedNitrogen;
        } catch (error) {
            console.error("Error fetching nitrogen data:", error);
            return []; // Return an empty array on error
    }
};

export const getNitrogenDataByRegion = async (): Promise<listItemRegions[]> => {
    try {
        const response = await fetch('/api/wastewater/municipalities/nitrogen');
        if (!response.ok) {
            console.log("===========Fetched data:", response)
            throw new Error("Failed to fetch nitrogen data for regions");
        }
        const nitrogenData = await response.json();
        
        // Map the API response to our listItemRegions structure
        const formattedNitrogen = nitrogenData.map((item: PollutantByRegionData) => ({
            regionName: item.municipality,
            pollutantAmt: item.count,
        }));
        return formattedNitrogen;
        } catch (error) {
            console.error("Error fetching nitrogen data:", error);
            return []; // Return an empty array on error
    }
};

export const getPhosphorusDataByRegion = async (): Promise<listItemRegions[]> => {
    try {
        const response = await fetch('/api/wastewater/municipalities/phosphorus');
        if (!response.ok) {
            throw new Error("Failed to fetch phosphorus data");
        }
        const phosphorusData = await response.json();
        // Map the API response to your 'Item' structure
        const formattedPhosphorus = phosphorusData.map((item: PollutantByRegionData) => ({
            regionName: item.municipality,
            pollutantAmt: item.count,
        }));
        return formattedPhosphorus;
        } catch (error) {
            console.error("Error fetching phosphorus data:", error);
            return []; // Return an empty array on error
    }
};

export const getSewageNitrogenDataByRegion = async (): Promise<listItemRegions[]> => {
    try {
        const response = await fetch('/api/sewage/municipalities/nitrogen');
        if (!response.ok) {
            throw new Error("Failed to fetch nitrogen data for regions");
        }
        const nitrogenData = await response.json();
        // Map the API response to our listItemRegions structure
        const formattedNitrogen = nitrogenData.map((item: PollutantByRegionData) => ({
            regionName: item.municipality,
            pollutantAmt: item.count,
        }));
        return formattedNitrogen;
        } catch (error) {
            console.error("Error fetching nitrogen data:", error);
            return []; // Return an empty array on error
    }
};

export const getSewagePhosphorusDataByRegion = async (): Promise<listItemRegions[]> => {
    try {
        const response = await fetch('/api/sewage/municipalities/phosphorus');
        if (!response.ok) {
            throw new Error("Failed to fetch phosphorus data");
        }
        const phosphorusData = await response.json();
        // Map the API response to your 'Item' structure
        const formattedPhosphorus = phosphorusData.map((item: PollutantByRegionData) => ({
            regionName: item.municipality,
            pollutantAmt: item.count,
        }));
        return formattedPhosphorus;
        } catch (error) {
            console.log("Error fetching phosphorus data:", error);
            return []; // Return an empty array on error
    }
};

export const getRegulationsCount = async(): Promise<RegulationsCount[]> => {
    try {
        const response = await fetch('/api/wastewater/regulations/count');
        if (!response.ok) {
            throw new Error("Failed to fetch regulations count");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching the regulations count data: ", error);
        return [];
    }
}

export const getExceedancesCountData = async(pollutant: string): Promise<ExceedancesCount[]> => {
    let param = 'nitrogen';
    if (pollutant === 'Phosphorus') {
        param = 'phosphorus';
    }

    try {
        const response = await fetch('/api/sewage/exceedances/' + param);
        if (!response.ok) {
            throw new Error("Failed to fetch sewage exceedances count");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching the sewage exceedances data: ", error);
        return [];
    }
}