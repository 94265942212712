// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main.css */

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  color: #333;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #e6f7ff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/AllContaminateTable.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,WAAW;EACX,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Main.css */\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin: 20px 0;\n}\n\nth,\ntd {\n  border: 1px solid #ccc;\n  padding: 8px;\n  text-align: left;\n}\n\nth {\n  background-color: #f2f2f2;\n  color: #333;\n}\n\ntr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\ntr:hover {\n  background-color: #e6f7ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
