import React from 'react';

const IntroPage: React.FC = () => {
  return (
    <div>
      <h1>WaterWize</h1>
      <div>Mizugraphs WaterWize application is built to track and visualize wastewater and sewage compliance data. Aggregating data from local municipalities and industries in Ontario, WaterWize focuses on regions of non-compliance to raise awareness of areas in need of improvement. By tracking the different contaminants and pollutants and their limits in the local water systems, we enable users to monitor and hope to put pressure on those in power to take action to improve the state of our waterways!  </div>
      <h1>Product Pitch</h1>
      <div>
        <h2>The Problem:</h2>   
        Water pollution from non-compliance with sewage and industrial wastewater emissions is a massive problem that threatens the sustainability of our water systems and poses a threat to the health of many. Due to increasing resource use, it is paramount that governments, industries, and other agencies have a way to track the current state of the waterways to be able to address the emissions made. Our product solves this problem by providing a dashboard that consolidates many key data points such as the pollutants, their limits, and current levels into different graphs representing the statistics. Users can view trends over time, identify industries and municipalities where the problems are highest to inform them of what is currently happening around Ontario.  
        <h2>The target users:</h2>  
        Our target users consist of environmental agencies, industrial facilities, and municipal governments. We want this platform to be used as a way for industries and local municipalities to monitor the current status so that they can effectively take action against the rising levels of pollutants and stop the rapid contamination. We also want environmental agencies to use our site as they can relay this information to the general public, spreading awareness and ensuring that the other bodies are held accountable.  
        <h2>Scope of App:</h2>  
        The application will consolidate data from industrial wastewater and sewage emissions, focusing on parameters such as pollutant levels, sampling frequency, regulation standards, and determining whether compliance is being broken. By breaking this down into industries and regions, the site will help drive better compliance and improve the health of our waterways in Ontario. 
      </div>
    </div>
  );
};

export default IntroPage;