import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import IntroPage from './components/introPage';
import AllContaminateTable from "./components/AllContaminateTable";
import PhosphorusList from "./components/phosphorusList";
import NitrogenList from "./components/nitrogenList";
import Navbar from './components/Navbar'
import { ReportData } from "./types/Wastewater";
import NitrogenListRegionDisplay from "./components/nitrogenListRegion";

import "./styles/Main.css";
import PhosphorusListRegionDisplay from "./components/phosphorusListRegion";
import Visualizations from "./components/Visualizations";

const App: React.FC = () => {
  const [data, setData] = useState<ReportData[]>([]); // Store fetched data
  const [error, setError] = useState<string | null>(null); // Error state
  const [showPhosphorus, setShowPhosphorus] = useState(true); // Toggle state for Phosphorus/Nitrogen

  const ASCENDING_ORDER = "ASC";
  const DESCENDING_ORDER = "DESC";

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/wastewater/all");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setData(result);
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError("An unknown error occurred");
        }
      }
    };

    fetchData();
  }, []);

  // Filter data based on the flag
  const filteredData = data.filter((report) => {
    if (showPhosphorus) {
      return report.parameterReportedAs === "AS PHOSPHORUS";
    } else {
      return report.parameterReportedAs === "AS NITROGEN";
    }
  });

  // Show error or the table
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Router>
      <div className="App">
      <Navbar />
      <Routes>
        <Route path="/visualizations" element={<Visualizations />} />
        <Route path="/about-us" element={<IntroPage />} />
        <Route path="/" element={
          <>
            <h1>WaterWize</h1>
            <h2>Under construction...</h2>
          </>
        } />
        <Route path="/regions" element={
          <>
            <h1>Information About Regions</h1>
            <h1>Lowest Polluting Regions</h1>
            <div className="header-container">
              <h2 className="phosphorus-header">
                Top 5 Lowest Phosphorus Polluting Regions
              </h2>
              <h2 className="nitrogen-header">
                Top 5 Lowest Nitrogen Polluting Regions
              </h2>
            </div>
            <div className="lowestEmissions">
              <PhosphorusListRegionDisplay order={ASCENDING_ORDER}/>
              <NitrogenListRegionDisplay order={ASCENDING_ORDER}/>
            </div>
            <h1>Highest Polluting Regions</h1>
            <div className="header-container">
              <h2 className="phosphorus-header">
                Top 5 Highest Phosphorus Polluting Regions
              </h2>
              <h2 className="nitrogen-header">
                Top 5 Highest Nitrogen Polluting Regions
              </h2>
            </div>
            <div className="highestEmissions">
              <PhosphorusListRegionDisplay order={DESCENDING_ORDER}/>
              <NitrogenListRegionDisplay order={DESCENDING_ORDER}/>
            </div>
          </>
        } />
        <Route path="/sectors" element={
          <>
            <h1>Environmental Report Data</h1>
            <button onClick={() => setShowPhosphorus(!showPhosphorus)}>
              Show {showPhosphorus ? "Nitrogen" : "Phosphorus"} Data
            </button>
            {/* Pass the filtered and displayed data to AllContaminateTable */}
            <AllContaminateTable data={filteredData} />
            <h1>Lowest Polluting Sectors</h1>
            <div className="header-container">
              <h2 className="phosphorus-header">
                Top 5 Lowest Phosphorus Polluting Sectors
              </h2>
              <h2 className="nitrogen-header">
                Top 5 Lowest Nitrogen Polluting Sectors
              </h2>
            </div>
            <div className="lowestEmissions">
              <PhosphorusList order={ASCENDING_ORDER}/>
              <NitrogenList order={ASCENDING_ORDER}/>
            </div>
            <h1>Highest Polluting Sectors</h1>
            <div className="header-container">
              <h2 className="phosphorus-header">
                Top 5 Highest Phosphorus Polluting Sectors
              </h2>
              <h2 className="nitrogen-header">
                Top 5 Highest Nitrogen Polluting Sectors
              </h2>
            </div>
            <div className="highestEmissions">
              <PhosphorusList order={DESCENDING_ORDER}/>
              <NitrogenList order={DESCENDING_ORDER}/>
            </div>
          </>
        } />
      </Routes>
      </div>
    </Router>
  );
};

export default App;
