// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.legend-indicator-container {
  height: 15px;
  width: 15px;
  margin: 0px 16px 0px 2px;
}

.navbar ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333
}
.navbar ul li {
  float: left;
}
.navbar ul li a{
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.navbar ul li a:hover {
  background-color: #111;
}
.navbar .active{
  background-color: #2469d6;
}`, "",{"version":3,"sources":["webpack://./src/styles/Main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB;AACF;AACA;EACE,WAAW;AACb;AACA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".legend-container {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.legend-indicator-container {\r\n  height: 15px;\r\n  width: 15px;\r\n  margin: 0px 16px 0px 2px;\r\n}\r\n\r\n.navbar ul{\r\n  list-style-type: none;\r\n  margin: 0;\r\n  padding: 0;\r\n  overflow: hidden;\r\n  background-color: #333\r\n}\r\n.navbar ul li {\r\n  float: left;\r\n}\r\n.navbar ul li a{\r\n  display: block;\r\n  color: white;\r\n  text-align: center;\r\n  padding: 14px 16px;\r\n  text-decoration: none;\r\n}\r\n.navbar ul li a:hover {\r\n  background-color: #111;\r\n}\r\n.navbar .active{\r\n  background-color: #2469d6;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
