import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  RadialLinearScale,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';

// Register required components for Chart.js
ChartJS.register(ArcElement, RadialLinearScale, Tooltip, Legend);

interface PolarAreaChartProps {
  data: ChartData<'polarArea'>;
  options: ChartOptions<'polarArea'>;
}

const PolarAreaChart: React.FC<PolarAreaChartProps> = ({ data, options }) => {
  const updatedOptions = {
    ...options,
    layout: {
      padding: {
        top: 0,
        bottom: 10,
        left: 10,
        right: 10,
      },
      margin: {
        top: 0,
      }
    },
  };

  return (
    <div style={{width: "100%", height: "100%"}}>
      <PolarArea data={data} options={updatedOptions} />
    </div>
  );
};

export default PolarAreaChart;
