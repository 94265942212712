import React, { useState, useEffect } from 'react';
import BarChart from './BarChart';
import { getExceedancesCountData } from "../services/wastewaterService";
import { ChartData, ExceedancesCount } from '../types/Wastewater';


const ExceedancesBarChart: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedContaminant, setSelectedContaminant] = useState<string>('Nitrogen');

  const [sortOrder, setSortOrder] = useState<string>("DEFAULT");

  const [filteredData, setFilteredData] = useState<ExceedancesCount[]>([]);
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch data
  const fetchData = async (contaminant: string) => {
    setLoading(true);
    try {
      const data: ExceedancesCount[] = await  getExceedancesCountData(contaminant);

      const map = new Map();
      for (let i = 0; i < data.length; i++) {
        if (map.has(data[i].siteMunicipality)) {
          const newObj = map.get(data[i].siteMunicipality);
          newObj.noOfExceedances += data[i].noOfExceedances;
          map.set(data[i].siteMunicipality, newObj);
        } else {
          map.set(data[i].siteMunicipality, data[i]);
        }
      }
      
      const iterator = map[Symbol.iterator]();
      const groupedData = [];
      for (const item of iterator) {
        groupedData.push(item[1]);
      }

      setFilteredData(groupedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const setupBarChart = (contaminant: string, chartData: ExceedancesCount[]) => {
    // Format the data to use in the chart
    const labels = chartData.map((item) => item.siteMunicipality);
    const values = chartData.map((item) => item.noOfExceedances);

    setChartData({
      labels,
      datasets: [
        {
          label: `Total Instances of Regulation Exceedance For ${contaminant}`,
          data: values,
          backgroundColor: contaminant === 'Nitrogen'
            ? 'rgba(75, 192, 192, 0.6)'
            : 'rgba(255, 159, 64, 0.6)',
          borderColor: contaminant === 'Nitrogen'
            ? 'rgba(75, 192, 192, 1)'
            : 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
        },
      ],
    });
  };

  // STUB
  const sortPollutantData = () => {
    if (filteredData && filteredData.length > 0) {
      let sortedData = filteredData.map(item => ({ ...item }));
      if (sortOrder === "ASC") {
        sortedData.sort((a, b) => a.noOfExceedances - b.noOfExceedances);
      } else if (sortOrder === "DESC") {
        sortedData.sort((a, b) => b.noOfExceedances - a.noOfExceedances);
      } else {
        sortedData = filteredData;
      }

      return sortedData;
    }
    return [];
  };


  // Runs when sorted
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      const sorted = sortPollutantData();
      setupBarChart(selectedContaminant, sorted);
    }
  }, [filteredData, sortOrder]);

  // Fetch data
  useEffect(() => {
    fetchData(selectedContaminant);
    const theData = sortPollutantData();
    setupBarChart(selectedContaminant, theData);
  }, [selectedContaminant]);

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ width: '80%', height: '80%', margin: '0 auto' }}>
        <h2>Total Number of Sewage Regulation Exceedances by Municipality</h2>
        <label hidden htmlFor="contaminant_selector">Select Contaminant</label>
        <select
          id="contaminant_selector"
          value={selectedContaminant}
          onChange={(e) => setSelectedContaminant(e.target.value)}
        >
          <option value="Nitrogen">Nitrogen</option>
          <option value="Phosphorus">Phosphorus</option>
        </select>

        <label hidden htmlFor="sorting_selector">Select Sort Order</label>
        <select
          id="sorting_selector"
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
        >
          <option value="DEFAULT">No sorting</option>
          <option value="ASC">Ascending</option>
          <option value="DESC">Descending</option>
        </select>

        {chartData && <BarChart data={chartData} />}
      </div>
    );
};

export default ExceedancesBarChart;

